<template>
   <main class>
    <v-app class>
      <v-container>
        <div class="row">
          <div v-if="roleName !== appRoles.LCE" class="col-md-10 mx-auto mb-5">
            <v-btn
              outlined
              color="primary"
              @click="downloadUserGuide(1)"
            >
               Admin User Guide
              <v-icon
                right
                dark
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
          </div>
          <div v-if="roleName !== appRoles.LCE" class="col-md-10 mx-auto mb-5">
            <v-btn
              outlined
              color="secondary"
              @click="downloadUserGuide(2)"
            >
              Approver User Guide
              <v-icon
                right
                dark
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
          </div>
          <div v-if="roleName === appRoles.LCE" class="col-md-10 mx-auto">
            <v-btn
              outlined
              color="success"
              @click="downloadUserGuide(3)"
            >
              LCE User Guide
              <v-icon
                right
                dark
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-app>
  </main>
</template>

<script>
import { userService } from '@/services'
import Roles from '@/utils/roles'

export default {
  data () {
    return {
      roleName: '',
      appRoles: {},
      userRoles: [],
      appRoleList: [
        {
          roleName: '',
          show: false
        }
      ]
    }
  },
  methods: {
    downloadUserGuide (val) {
      const baseUrl = `https://${location.host}`
      switch (val) {
        case 1:
          window.open(`${baseUrl}/userguide/CMMS User Guide - Admin.pdf`, '_blank')
          break
        case 2:
          window.open(`${baseUrl}/userguide/CMMS User Guide - Approver.pdf`, '_blank')
          break
        case 3:
          window.open(`${baseUrl}/userguide/CMMS User Guide - LCE.pdf`, '_blank')
          break
        default:
          break
      }
      // await timesheetService.downloadUserGuide(val).then(result => {
      //   let fileName = ''
      //   const disposition = result.headers['content-disposition']
      //   if (disposition && disposition.indexOf('attachment') !== -1) {
      //     const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      //     const matches = filenameRegex.exec(disposition)
      //     if (matches != null && matches[1]) {
      //       fileName = matches[1].replace(/['"]/g, '')
      //     }
      //   }

      //   const fileUrl = window.URL.createObjectURL(new Blob([result], { type: 'application/pdf' }))
      //   const link = document.createElement('a')
      //   link.href = fileUrl
      //   link.setAttribute('download', fileName)
      //   document.body.appendChild(link)
      //   link.click()
      // }).catch(error => {
      //   console.log(error)
      // })
    }
  },
  mounted () {
    this.appRoles = Roles
    userService.getCurrentUser().then(res => {
      this.roleName = res.data.roleName
      this.userRoles = res.data.roles
    })
  }
}
</script>

<style>

</style>
